import { Component, OnInit, Input, Output, EventEmitter, OnChanges, HostListener, OnDestroy } from '@angular/core';
import { AgendaAmbulatoriaService } from 'src/app/services/agenda-ambulatoria.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import gtag from 'ga-gtag';
import { ErrorReservaComponent } from 'src/app/shared/components/modals/error-reserva/error-reserva.component';
import { ConfirmarAnularReservaComponent } from 'src/app/shared/components/modals/confirmar-anular-reserva/confirmar-anular-reserva.component';
import { MatDialog } from '@angular/material';
import * as $ from 'jquery';
import { ACTIVAR_MARCA_ORIGEN_DERIVACION, ENV } from 'src/environments/environment';
import * as moment from 'moment';
import 'moment-timezone';
import 'moment/locale/es';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.scss']
})
export class ConfirmacionComponent implements OnInit, OnChanges, OnDestroy {

  //public reservaFinalizada:boolean = false;

  @Input() paciente: any;
  @Input() calendario: any;
  @Input() busquedaInicial: any;
  @Input() reservaFinalizada: boolean;
  @Input() fromSaludIntegral: boolean = false;
  @Output() confirmarReserva: EventEmitter<any> = new EventEmitter();
  @Output() confirmarAnular: EventEmitter<any> = new EventEmitter();
  @Output() reservaAnulada: EventEmitter<any> = new EventEmitter();
  @Output() tomarOtraHora: EventEmitter<any> = new EventEmitter();

  @Input() mensajes: any = [];

  disableExpand = true;
  disableBarReserva = false;
  expanded = { reserva: true, info: true }
  reservaSubscribe;
  resetReservaSubscribe;
  verMasOpened = false;
  verMasAction = false;
  idreserva;
  codCita;
  successMsg = '¡Reserva Exitosa!'
  anulada = false;
  autorizaPago = true;
  horaPresentacion;
  constructor(
    public agendaService: AgendaAmbulatoriaService,
    public utils: UtilsService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.onResize();

    this.reservaSubscribe = this.utils.getEmitReservar().subscribe(res => {
      this.reservar();
    });

    this.resetReservaSubscribe = this.utils.getReiniciarReserva().subscribe(res => {
      this.anulada = false;
      this.verMasOpened = false;
      this.verMasAction = false;
      this.successMsg = '¡Reserva Exitosa!';
      this.disableExpand = true;
      this.disableBarReserva = false;
    });

  }

  ngOnChanges() {
    this.onResize();
    setTimeout(() => {
      this.verMas();
      this.setVerMas('close')
      this.successMsg = '¡Reserva Exitosa!'
    }, 300)
  }

  ngOnDestroy() {
    this.reservaSubscribe.unsubscribe();
    this.resetReservaSubscribe.unsubscribe();
  }

  procesarOtraHora() {
    this.tomarOtraHora.emit(true)
  }
  async reservar() {

    this.disableBarReserva = true;
    this.utils.actionLoaderReserva().set(true);
    let adicionalData: any = {};
    const { fechaHora, compensacion } = this.calendario.cupo;
    let fecha: any = this.utils.toLocalScl(fechaHora, compensacion);
    let postData:any = {
      idArea: this.busquedaInicial.area.id,
      fechaInicioDesde: fecha,
      idCentro: this.calendario.cupo.centro.id,
      duracion: this.calendario.cupo.duracion,
      idPaciente: this.paciente.adicional.documento,
      idPacientePRM: this.paciente.id,
      idServicio: this.busquedaInicial.especialidad.idServicio,
      idPlanCobertura: this.paciente.adicional.prevision.idPlan
    }

    if (this.busquedaInicial.area.id === 'RIS_IMAGENES') {

      const datosImagenes = this.busquedaInicial.datosImagenes;
      const fichero = datosImagenes.listadoArchivos.length ? datosImagenes.listadoArchivos[0].file64 : null;
      adicionalData = {
        idEncuesta: datosImagenes.idEncuesta,
        requierePresupuesto: datosImagenes.requierePresupuesto ? 1 : 0,
        ordenMedica: fichero,
      }
      this.horaPresentacion = moment(fechaHora).utcOffset(compensacion ? compensacion : -180).subtract(20, 'm');

    } else {

      let urlOrdenesAdjunta = [];

      if (this.busquedaInicial.datosImagenes && this.busquedaInicial.datosImagenes.requiereOrden) {
        try {
          const token = this.busquedaInicial.datosImagenes.tokenRequiereOrden;
          const listadoArchivos = this.busquedaInicial.datosImagenes.listadoArchivos;
          const prepareUploadFiles = listadoArchivos.map(file => {
            return this.agendaService.cargarOrdenMedica(token, file)
          });
          const urlOrdenesAdjuntaProm = await Promise.all(prepareUploadFiles);
          urlOrdenesAdjunta = urlOrdenesAdjuntaProm.filter((item: any) => item.urlOrden);
        } catch (err) {
          console.log(err);
        }
      }

      adicionalData = {
        idRecurso: this.calendario.recurso.id,
        tipoIdPaciente: this.paciente.adicional.tipoDocumento,
        paisIdentificador: 'CL',
        idDisponibilidad: this.calendario.cupo.disponibilidad.id,
        idTipoCita: this.calendario.cupo.idTipoCita.id
      }

      if (urlOrdenesAdjunta.length) {
        adicionalData.urlOrdenesAdjunta = urlOrdenesAdjunta;
      }
    }

    postData = { ...postData, ...adicionalData }

    try {
      const aplicaMarcaDerivacion = sessionStorage.getItem("aplicaMarcaDerivacion") === 'true';
      if (aplicaMarcaDerivacion && ACTIVAR_MARCA_ORIGEN_DERIVACION) {
        postData.origen = 'RME';
      }
    } catch (err) {
      console.log(err);
    }

    this.agendaService.postCita(postData).subscribe(async (data: any) => {

      if (data['statusCod'] == 'OK') {

        if (!this.fromSaludIntegral) {
          this.setAutorizaPago();
        }

        gtag('config', ENV.analyticsCode,
          { 'page_path': `/busqueda/especialidad/area/${this.busquedaInicial.area.id}/profesional/${this.calendario.recurso.id}servicio/${this.busquedaInicial.especialidad.idServicio}/centro/${this.busquedaInicial.centroAtencion.idCentro}/cupo/reservado/${data['idCita']}` });

        if (data.hasOwnProperty('listaRecetas') && data.listaRecetas.length > 0) {
          this.setListaRecetas(data.listaRecetas);
        }

        let emailMedico = ENV.correoExamenesAdjuntos ? ENV.correoExamenesAdjuntos : '';

        if (!emailMedico) {
          try {
            const medicoProfesional: any = await this.agendaService.getDatosProfesional(this.calendario.recurso.id).toPromise();
            emailMedico = medicoProfesional && medicoProfesional.datosProfesional ? medicoProfesional.datosProfesional.email : null;
          } catch (err) {
            emailMedico = null;
          }
        }

        this.reservaFinalizada = true;
        this.idreserva = data['idCita'];
        data.paciente = this.paciente,

          this.confirmarReserva.emit({
            response: true,
            data,
            idPaciente: this.paciente.adicional.documento,
            idMedico: this.calendario.recurso.id,
            emailMedico,
          });

        this.utils.actionLoaderReserva().set(false);

      } else {

        this.errReserva(data['usrMsg']);
        this.utils.actionLoaderReserva().set(false);

      }

      this.disableBarReserva = false;
      sessionStorage.removeItem("aplicaMarcaDerivacion");

      /******* GTM PUSH *******/
      try {
        const cupo = this.calendario && this.calendario.cupo ? this.calendario.cupo : { valorConvenio: "", fechaHora: "" };
        gtag('event', 'Paso_4', {
          event: 'Paso_4',
          environment: ENV.name,
          userLogin: false,
          confirmacion_exito: data['statusCod'] == 'OK' ? 'SI' : 'NO',
          confirmacion_completado: data['statusCod'] == 'OK' ? 'SI' : 'NO',
          seleccion_fecha_hora: cupo.fechaHora,
          dimension_area: this.busquedaInicial.area.nombre,
          dimension_especialidad: this.busquedaInicial.especialidad.nombreEspecialidad,
          dimension_profesional_p1_p2: this.calendario.recurso.nombre,
          dimension_centro: this.busquedaInicial.centroAtencion.nombre,
          dimension_servicio: this.busquedaInicial.especialidad.nombreServicio,
          valor_convenio: cupo.valorConvenio
        })
      } catch (err) {
        console.log("GTM PASO_4 ERROR", err);
      }
      /***********************/
    })

  }


  async validarRequiereOrden() {
    try {
      const data = {
        idArea: this.busquedaInicial.area.id,
        idServicio: this.busquedaInicial.especialidad.idServicio,
        idCentro: this.calendario.cupo.centro.id
      };
      return await this.agendaService.validarRequiereOrden(data);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  get displayValorConvenio() {
    const areaSlugified = this.utils.slugify(this.busquedaInicial.area.nombre, '-');
    const cupo = this.calendario.cupo;
    const validArea = areaSlugified.includes('consultas') || areaSlugified.includes('telemedicina') /*|| areaSlugified.includes('procedimiento');*/
    return cupo && cupo.valorConvenio && cupo.valorConvenio.toString().trim() != '0' && validArea;
  }

  setListaRecetas(items) {

    const mensajes = [];
    mensajes.push({ mensaje: { contenido: `<b style="margin: 20px 0px;font-weight: bold;font-size: 16px; display:block;">Documentos de la Cita:</b>` } });
    items.forEach((val, key) => {
      mensajes.push({ mensaje: { contenido: `<p style="margin:0px !important">- <a href="${val.urlReceta}" target="_blank">${val.nombreReceta}</a></p>` } });
    });

    this.mensajes = this.mensajes.concat(mensajes);
    this.onResize();

  }

  errReserva(message) {

    let msg = (message) ? message : 'Se ha producido un error. ¿Que desea hacer?';

    let dialogRef = this.dialog.open(ErrorReservaComponent, {
      width: '720px',
      data: { message: msg },
      autoFocus: false,
      disableClose: true
    });

    dialogRef.componentInstance.dialogEvent.subscribe((result) => {
      this.utils.setReloadBusqueda();
    })

  }

  @HostListener('window:resize', [])
  onResize(): void {

    if (window.innerWidth <= 960) {
      this.disableExpand = false;
    } else {
      this.disableExpand = true;
      this.expanded = { reserva: true, info: true }
    }

    this.verMas();
  }

  verMas() {

    const hDatosReserva = $("#contDatosReserva").height();
    const hIndic = $("#contIndic").height();
    if (hDatosReserva !== undefined) {
      if (hIndic > hDatosReserva) {
        this.verMasAction = true;
        $("#contIndic").css({
          height: (hDatosReserva + 42) + 'px'
        })
      } else {
        this.verMasAction = false;
        $("#contIndic").css({
          height: '',
        })
      }
    }
  }

  setVerMas(action) {
    if (action === 'open') {
      this.verMasOpened = true;
      $("#contIndic").css({
        height: '',
        paddingBottom: '30px'
      })
    } else {
      this.verMasOpened = false;
      this.verMas();
    }
  }

  pagar() {
    gtag('event', 'Reserva Exitosa', { 'event_category': 'Pagar Reserva', 'event_label': 'Pagar', 'value': '0' });
    location.href = 'https://cmv.ucchristus.cl/pago/reserva/' + this.idreserva;
  }

  anularReserva() {

    const data = {
      fromReserva: true,
      nombreProfesional: this.calendario.recurso.nombre,
      fechaHora: this.calendario.cupo.fechaHora,
      compensacion: this.calendario.cupo.compensacion,
      IdArea: this.busquedaInicial.area.id,
      nombreEspecialidad: this.busquedaInicial.especialidad.detalle,
      nombreServicio: this.busquedaInicial.especialidad.nombreServicio,
      Centro: {
        Nombre: this.calendario.cupo.centro.nombre
      }
    };

    let dialogConfirm = this.dialog.open(ConfirmarAnularReservaComponent, {
      data: data,
      width: '640px',
      autoFocus: false
    });

    dialogConfirm.componentInstance.dialogEvent.subscribe(result => {

      if (result) {

        const dataCita = {
          idCita: this.idreserva,
          codPaciente: this.busquedaInicial.documentoPaciente.documento,
          tipoIdPaciente: this.busquedaInicial.documentoPaciente.tipoDocumento,
          paisIdentificador: 'CL',
          codEstado: 'ANULADA'
        }

        this.agendaService.cambiarEstadoCita(dataCita).subscribe(res => {

          if (res['statusCod'] == 'OK') {
            this.autorizaPago = false;
            this.anulada = true;
            this.successMsg = '¡Reserva ha sido anulada correctamente!';
            gtag('event', 'Reserva Exitosa', { 'event_category': 'Anular Reserva', 'event_label': 'Anular', 'value': '0' });
            this.agendaService.crearMarca({
              dni: this.busquedaInicial.documentoPaciente.documento,
              dni_type: this.busquedaInicial.documentoPaciente.tipoDocumento,
              element: 'BOTÓN ANULAR HORA EN CONFIRMACIÓN RESERVA',
              value: JSON.stringify(dataCita),
              detail: 'PACIENTE HACE CLIC EN ANULAR HORA EN PÁGINA CONFIRMACIÓN DE RESERVA',
              source: 'PÁGINA CONFIRMACIÓN',
              into:'etapas'
            });
          } else {
            this.utils.mDialog("Error", "La Hora ha sido no pudo ser anulada. Intente nuevamente.", "message");
          }

          this.reservaAnulada.emit(true);
        });
      }
    });
  }

  setAutorizaPago() {
    const centro = this.calendario.cupo.centro.id;
    const idservicio = this.busquedaInicial.especialidad.idServicio;
    const idplansalud = this.paciente.adicional.prevision.idPlan;

    this.agendaService.getAutorizaPago(centro, idservicio, idplansalud).then((res: any) => {
      this.autorizaPago = res.realizaPago;
    }).catch(err => {
      this.autorizaPago = false;
    })
  }

}

